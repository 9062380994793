<template>
    <div>
        <div align="center">
            <h4 class="q-mb-sm">Definisci Quietanza</h4>
            <h5 class="q-mt-sm q-mb-sm">Pratica N. {{RecordSelezionato.numero_interno}}</h5>
            <br>
            <hr>
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-5 justify-center">
                <h5 >Allega PDF Titolo di quietanza</h5>
                <q-uploader
                    ref="FilesAllegati"
                    url="https://localhost:1000"
                    color="teal"
                    label="Trascina QUI i documenti di questa Quietanza"
                    multiple
                    name="upload_quietanza"
                    hide-upload-btn
                    :filter="checkFileType"
                    style="width: 100%; min-height: 350px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
                    @rejected="onRejected()"
                    @removed="onRimuoviFileAllegati"
                    @added="onAggiungiFileAllegati"
                />
                <br>
            </div>

            <div class="col-12 col-md-5 q-px-md">
                <q-list separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Premio</q-item-label>
                            <q-item-label caption><h4>{{quietanza_da_definire.premio}} Euro</h4></q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-item-label>Contraente</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.name.toUpperCase()}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Tariffa</q-item-label>
                            <q-item-label caption>{{quietanza_da_definire.prodotto_id.toUpperCase()}}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-item-label>Compagnia</q-item-label>
                            <q-item-label caption>{{quietanza_da_definire.compagnia}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Fornitore</q-item-label>
                            <q-item-label caption>{{quietanza_da_definire.fornitore}}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-item-label>Decorrenza quietanza</q-item-label>
                            <q-item-label caption>{{TranscodificaData(quietanza_da_definire.decorrenza)}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Scadenza quietanza</q-item-label>
                            <q-item-label caption>{{TranscodificaData(quietanza_da_definire.scadenza)}}</q-item-label>
                        </q-item-section>
                    </q-item>

                </q-list>
                <br>
                <br>

                <div class="col-12 col-md-5 q-px-md">
                    <h5>Dati del Titolo di quietanza</h5>
                    <q-input outlined
                        dense
                        v-model="dati_modificati.decorrenza"
                        mask="##/##/####"
                        name="decorrenza"
                        label="Data di decorrenza quietanza"
                        :rules="[ val => !isNotValid(val) || 'Data di decorrenza errata' ]">

                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date
                                        mask="DD/MM/YYYY"
                                        v-model="dati_modificati.decorrenza"
                                        >
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Close" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>

                    </q-input>

                    <q-input outlined
                        dense
                        mask="##/##/####"
                        name="scadenza"
                        v-model="dati_modificati.scadenza"
                        label="Data di scadenza quietanza"
                        :rules="[ val => !isNotValid(val) || 'Data di scadenza errata' ]">

                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date
                                        mask="DD/MM/YYYY"
                                        v-model="dati_modificati.scadenza"
                                        >
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Close" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>

                    </q-input>

                    <q-input outlined
                        dense
                        mask="##/##/####"
                        name="data_incasso"
                        :rules="[ val => !isNotValid(val) || 'Data di Incasso errata' ]"
                        v-model="dati_modificati.data_incasso"
                        label="Data di incasso quietanza">

                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date
                                        mask="DD/MM/YYYY"
                                        v-model="dati_modificati.data_incasso"
                                        >
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Close" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>

                    </q-input>

                    <q-select outlined
                        dense
                        error-message="Devi definire se la quietanza richiede il Simplo oppure no"
                        v-model="dati_modificati.abilita_simplo"
                        :options="elenco_dati_simplo"
                        label="ABILITA RCHIESTA SIMPLO"
                        name="abilita_richiesta_simplo"
                        :rules="[ val => val !== '' || 'Devi definire se la quietanza richiede un Simplo oppure no' ]"
                        emit-value
                        map-options
                        virtual-scroll-slice-size="0"
                        virtual-scroll-item-size="0"
                    />

                </div>
            </div>

            <div class="col-12 col-md-12 justify-center">
                <br>
                <hr>
                <div class="col-12 col-md-10 row">

                    <div class="col-12 col-md-6" align="center">
                        <QQButton label="INDIETRO"
                            color="blue-grey"
                            icon="undo"
                            size="md"
                            icon_align="left"
                            :disable="pulsante_disattivato"
                            @click.native="onIndietroClicked()"
                        />
                    </div>

                    <div class="col-12 col-md-6" align="center">
                        <QQButton
                            label="Registra"
                            color="blue-grey"
                            icon="mdi-content-save-edit-outline"
                            size="md"
                            icon_align="right"
                            :disable="pulsante_disattivato"
                            @click.native="onRegistraClicked()"
                        />
                    </div>
                </div>
            </div>

        </div>
        <br><br><br><br><br><br>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy";
    import axios from 'axios';
    import commonLib from "@/libs/commonLib";
    import {mapFields} from "vuex-map-fields"
    import { mapActions } from "vuex";

    export default {
        name: "DefinisciQuietanza",
        data() {
            return {
                dati_modificati: {
                    decorrenza: "",
                    scadenza: "",
                    data_incasso: "",
                    modalita_incasso: "contanti",
                    abilita_simplo: ""
                },
                elenco_files : [],
                NumeroPratica: "",
                elenco_dati_simplo: [
                    {label: "Si", value: true },
                    {label: "No", value: false }
                ],
                pulsante_disattivato: false
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields("gestionePratiche", {
                RecordSelezionato: "RecordSelezionato",
                dati_cliente: "dati_cliente"
            }),
            ...mapFields("gestioneRinnovi", {
                quietanza_da_definire: "quietanza_da_rinnovare"
            }),
            getCurrentDate() {
                var data = new Date();
                var gg = (data.getDate()+"").padStart(2,'0');
                var mm = ((data.getMonth()+1)+"").padStart(2,'0');
                var DataOggi = gg+"/"+mm+"/"+data.getFullYear();

                return DataOggi;
            },
            ControllaSeCampiCompilati() {
                var esito = true;
                esito = esito && (this.dati_modificati.decorrenza !== "");
                esito = esito && (this.dati_modificati.scadenza !== "");
                esito = esito && (this.dati_modificati.data_incasso !== "");
                esito = esito && (this.elenco_files.length !== 0);
                esito = esito && (this.dati_modificati.abilita_simplo !== "");

                return esito;
            }
        },
        methods: {
            ...mapActions({
                definisciQuietanza: "gestioneRinnovi/definisciQuietanza",
                fetchUploadUrlQuietanza: "gestioneDocumenti/fetchUploadUrlQuietanza"
            }),
            TranscodificaData(data) {
                if (commonLib.isEmpty(data)) return "";

                data = data.substring(0,10);
                var elements = data.split("-");
                var gg = elements[2].padStart(2,'0');
                var mm = elements[1].padStart(2,'0');
                var aa = elements[0];

                return gg+"/"+mm+"/"+aa;
            },
            isDate(value) {
                if (value === "") return false;
                if (value.length != 10) return false;
                let elements = value.split("/");
                if (elements.length != 3) return false;

                return true;
            },
            isValidDate(value) {
                let elements = value.split("/");
                let GiorniPerMese = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

                // Il controllo vale per anni bisestili
                if (elements[0] > GiorniPerMese[parseInt(elements[1])]) return false;
                if (parseInt(elements[1]) > 12) return false;

                // Controlla anni non bisestili
                if (parseInt(elements[1]) == 2 && !commonLib.isAnnoBisestile(elements[2])) {
                    if (elements[0] > 28) return false;
                }

                return true;
            },
            isNotValid(valore) {
                if ((commonLib.isEmpty(valore)) || (valore === "")) return true;

                if (this.isDate(valore)) {
                    // Verifica che non si tratti di una data scritta a cazzo di cane
                    return !this.isValidDate(valore);
                }

                return false;
            },
            DateToDbFormat(data) {
                var elements = data.split("/");
                return elements[2]+"-"+elements[1].padStart(2,'0')+"-"+elements[0].padStart(2,'0');
            },
            async InviaDocumentiAlServer() {

                this.elenco_files.forEach(async file => {

                    var url = await this.fetchUploadUrlQuietanza(
                        {
                            NumeroPratica: this.NumeroPratica,
                            Filename: file.name,
                            IDQuietanza: this.quietanza_da_definire.idquietanza
                        }
                    );

                    let formData = new FormData();
                    formData.append('files[0]', file);

                    await axios.put(
                        url,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(function(){
                            console.log('SUCCESS!!');
                        }).catch(function(){
                            console.log('FAILURE!!');
                        });
                });
            },
            disattivaPulsanti() {
                this.pulsante_disattivato = true;

            },
            onRegistraClicked() {
                if (!this.ControllaSeCampiCompilati) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Sono stati rilevati errori sui dati. Controlla di aver allegato il documento con il titolo di quietanza e di aver compilato tutti i campi.',
                    });
                    return;
                }

                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No, neanche un po`",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, sono sicuro"
                    },
                    title: 'Attenzione',
                    message: 'Sei sicuro di voler definire questa Quietanza?',
                }).onOk(async () => {
                    this.disattivaPulsanti();

                    // invia tutti i documenti al server S3
                    await this.InviaDocumentiAlServer();

                    // Invia i dati al backend per registrare le informazioni sulla nuova quietanza
                    await this.definisciQuietanza({
                        id_quietanza: this.quietanza_da_definire.idquietanza,
                        _decorrenza: this.DateToDbFormat(this.dati_modificati.decorrenza),
                        _scadenza: this.DateToDbFormat(this.dati_modificati.scadenza),
                        dataIncasso: this.DateToDbFormat(this.dati_modificati.data_incasso),
                        abilitaSimplo: this.dati_modificati.abilita_simplo
                    });

                    // Torna al menu principale delle pratiche
                    this.$router.push({name : "Pratiche.MenuGestionePratiche"});

                });
            },
            onIndietroClicked() {
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            },
            onRejected () {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Il tipo di documento allegato può essere solo nel formato PDF. Gli altri formati non sono accettati per questioni di sicurezza.'
                });
            },
            onRimuoviFileAllegati(files) {
                for (var i=0;i<this.elenco_files.length;i++) {
                    if (this.elenco_files[i] === files[0]) {
                        this.elenco_files.splice(i,1);
                    }
                }
            },
            onAggiungiFileAllegati(files) {
                this.elenco_files.push(files[0])
            },
            checkFileType(files) {
                let status = (files.filter(file => file.type === 'application/pdf').length === 1) ||
                             (files.filter(file => file.type === 'application/vnd.fdf').length === 1);
                if (status) {
                    return files.filter(file => file.type === files[0].type);
                }

                return [];
            },
        },
        async mounted() {
            //console.log("quietanza_da_definire:",this.quietanza_da_definire);
            //console.log("record selezionato:",this.RecordSelezionato);
            window.scrollTo(0,0);
            this.dati_modificati.decorrenza = this.TranscodificaData(this.quietanza_da_definire.decorrenza);
            this.dati_modificati.scadenza = this.TranscodificaData(this.quietanza_da_definire.scadenza);
            this.dati_modificati.data_incasso = this.getCurrentDate;
            this.elenco_files = [];
            this.NumeroPratica = this.RecordSelezionato.numero_interno;
        },
    }
</script>
